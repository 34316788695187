<template>
	<van-button type="primary" round class="icon-button" :color="vanButton.background" :style="style" @click="emitClick">
		<slot>
			<van-icon v-if="vanIcon.name" :name="(vanIcon && vanIcon.name) || 'chat-o'" :size="(vanIcon && vanIcon.size) || 18" :color="(vanIcon && vanIcon.color) || 'white'" />
		</slot>
	</van-button>
</template>

<script>
export default {
	props: {
		vanButton: {
			type: Object,
			default: () => {}
		},
		vanIcon: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			style: {
				"--size": this.vanButton.size ? `${this.vanButton.size}px` : `40px`
			}
		};
	},
	methods: {
		emitClick() {
			this.$emit("click");
		}
	}
};
</script>

<style lang="scss" scoped>
.icon-button {
	width: var(--size);
	height: var(--size);
	border-color: transparent !important;
}
</style>
