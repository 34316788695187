<template>
	<div class="selected-category" :style="{ backgroundColor: `#${item.color}` }">
		<div class="category-emoji">{{ item.emoji }}</div>
		{{ item.title }}
		<div class="close-btn" @click="deleteEmit">
			<img src="@/assets/images/close.svg" alt="..." />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},
	methods: {
		deleteEmit() {
			this.$emit("click");
		}
	}
};
</script>

<style lang="scss" scoped>
.selected-category {
	display: inline-flex;
	align-items: center;
	padding: 8px 14px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border-radius: 100px;

	.category-emoji {
		margin-right: 8px;
	}

	.close-btn {
		width: 16px;
		height: 16px;
		margin-left: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(14, 16, 18, 0.64);
		border-radius: 50%;
		cursor: pointer;
	}
}
</style>
