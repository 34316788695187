<template>
	<div
		class="card"
		:style="{
			backgroundColor: item.status === 0 ? `#${item.category.color}` : '#B3B3B3'
		}"
	>
		<div class="card-header">
			<h3 class="card-title">{{ item.title }}</h3>
			<Members :array="item.members" />
		</div>

		<div class="card-footer">
			<ul v-if="item.joined === 0" class="tags">
				<li v-if="item.start_date" class="tag">🧨 с {{ item.start_date }}</li>
				<li class="tag">⏳ {{ frequency }}</li>
				<!-- <li class="tag">💸 10$</li> -->
			</ul>
			<div class="card-date">
				<div
					:class="[
						'date-day',
						{
							infinity: item.duration == 0 ? true : false
						}
					]"
				>
					{{ item.duration == 0 ? "8" : item.duration }}
				</div>
				<div class="date-name">дней</div>
			</div>
		</div>
	</div>
</template>

<script>
import Members from "@/components/Global/Members.vue";
export default {
	components: { Members },
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		frequency() {
			if (this.item.start_type === 0) {
				return "Каждый день";
			} else if (this.item.type === 1) {
				return `${this.item.freq} раз`;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.card {
	padding: 24px;
	color: rgba(14, 16, 18, 0.64);
	background: #d398a2;
	border-radius: 32px;

	.card-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
	}

	.card-title {
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
	}

	.card-footer {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}

	.card-date {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: auto;
		font-family: "Prata";

		.date-day {
			margin-bottom: 6px;
			font-size: 32px;
			&.infinity {
				transform: rotateZ(90deg);
			}
		}

		.date-name {
			font-size: 12px;
		}
	}
}
</style>
