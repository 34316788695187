<template>
  <div class="container">
    <div class="catalog-page">
      <div class="page-header">
        <IconButton
          class="index-100"
          :van-button="{ background: '#E5E7E1' }"
          :van-icon="{ name: 'arrow-left', size: 20, color: '#0E1012' }"
          @click="btnBack()"
        />
        Каталог
        <IconButton
          class="index-100"
          :van-button="{ background: '#E5E7E1' }"
          @click="handleBubbles"
        >
          <img
            v-if="!isShowBubbles"
            src="@/assets/images/bubble.svg"
            alt="..."
          />
          <img v-else src="@/assets/images/row-vertical.svg" alt="..." />
        </IconButton>
      </div>
      <div class="search-holder">
        <InputSearch
          v-model="search"
          @input="searchDealsByQ"
          ref="searchInput"
        />

        <div class="selected-categories">
          <SelectedCategory
            v-if="search && search.length > 0"
            :item="{ title: search, color: 'e5e7e1' }"
            @click="clearSearch"
          />
          <template v-if="selectedCategories.length > 0">
            <SelectedCategory
              v-for="category in selectedCategories"
              :key="category.id"
              :item="category"
              @click="deleteSelectedCategory"
            />
          </template>
        </div>
      </div>
      <van-skeleton title :row="6" :loading="isLoading">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          :loading-text="$t('loading')"
          @load="onLoad"
        >
          <Card
            v-for="deal in deals"
            :key="deal.id"
            :item="deal"
            @click.native="goTo(deal)"
          />
        </van-list>
      </van-skeleton>
      <KeepAlive>
        <van-popup
          v-model="isShowBubbles"
          position="bottom"
          :style="{ height: '100%' }"
        >
          <div class="bubbles-holder" id="spreadCircles">
            <Bubble
              v-for="category in categoryList"
              :key="category.id"
              :item="category"
              @click="selectCategory(category)"
            />
          </div>
        </van-popup>
      </KeepAlive>

      <IconButton
        class="add-button index-100"
        :van-button="{ background: '#0F1113', size: 56 }"
        :vanIcon="{ name: 'plus', size: 22, color: 'white' }"
        @click="addTask()"
      />
    </div>
    <!-- <van-overlay
      :show="isLoading"
      @click="isLoading = false"
      class="van-overlay-loading"
    >
      <div class="wrapper-loading" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import IconButton from "@/components/Global/IconButton.vue";
import InputSearch from "@/components/Catalog/inputSearch.vue";
import Bubble from "@/components/Catalog/Bubble.vue";
import Card from "@/components/Catalog/Card.vue";
import SelectedCategory from "@/components/Catalog/SelectedCategory.vue";
import { mapGetters } from "vuex";
import ApiDeals from "@/api/deals.js";

import { eventBus } from "@/main";
export default {
  components: {
    IconButton,
    InputSearch,
    Bubble,
    Card,
    SelectedCategory,
  },
  data() {
    return {
      isShowBubbles: false,
      isLoading: false,
      loading: false,
      finished: false,

      deals: [],
      selectedCategories: [],
      search: null,
      debounce: null,
      params: {
        limit: 10,
        offset: 0,
      },
    };
  },
  computed: {
    ...mapGetters("Category", ["categoryList", "choosenCategory"]),
  },
  methods: {
    btnBack() {
      if (history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    addTask() {
      eventBus.$emit("addTask");
    },
    goTo(item) {
      this.$router.push(`/deal/${item.id}`);
    },
    handleBubbles() {
      this.isShowBubbles = !this.isShowBubbles;

      this.$nextTick(() => {
        this.setBubblesPosition();
      });
    },
    clearSearch() {
      this.search = "";
      this.$refs.searchInput.value = "";
      this.getDeals();
    },
    searchDealsByQ(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.search = event;
        this.getDealsByQ();
      }, 2000);
    },
    deleteSelectedCategory(id) {
      const objWithIdIndex = this.selectedCategories.findIndex(
        (obj) => obj.id === id
      );

      this.selectedCategories.splice(objWithIdIndex, 1);
      this.getDeals();
    },
    selectCategory(category) {
      this.selectedCategories.push(category);
      this.isShowBubbles = false;

      this.getDealsByCategory(category.id);
    },
    getDealsByCategory(id) {
      ApiDeals.getDealsByCategory(id)
        .then((response) => {
          this.deals = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onLoad() {
      if (this.deals.length >= 9) {
        this.params.offset += 10;
        ApiDeals.getDeals(this.params).then((responce) => {
          this.loading = false;
          this.deals.push(...responce.data);
          if (responce.data.length < 1) {
            this.finished = true;
          }
        });
      }
    },
    getDeals() {
      this.isLoading = true;
      ApiDeals.getDeals()
        .then((response) => {
          this.isLoading = false;
          this.deals = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDealsByQ() {
      this.isLoading = true;
      ApiDeals.searchDeals(this.search)
        .then((response) => {
          this.isLoading = false;
          this.deals = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setBubblesPosition() {
      function overlaps(x1, y1, radius1, x2, y2, radius2) {
        var xDistance = x1 - x2;
        var yDistance = y1 - y2;

        var sumOfRadii = radius1 + radius2;
        var sumOfRadiiSquared = sumOfRadii * sumOfRadii;
        var distanceSquared = xDistance * xDistance + yDistance * yDistance;

        return distanceSquared < sumOfRadiiSquared;
      }

      // function adjustLayout(containerID) {
      //   var container = document.getElementById(containerID);

      //   var bubbles = container.querySelectorAll(".bubble");

      //   var minGapBetweenCircle = 8;

      //   var plottedCircleInfo = [];

      //   var radius, plotX, plotY;

      //   var centerX = container.offsetWidth / 2;
      //   var centerY = container.offsetHeight / 2;

      //   var plotXYRadius = 1;
      //   var plotXYRadians = 0;

      //   var radius = 32;

      //   for (var i = 0; i < bubbles.length; i++) {
      //     var failed = true;
      //     while (failed) {
      //       radius = bubbles[i].offsetWidth / 2;

      //       var plotXYRadiusOffset = plotXYRadius + Math.random() * 10;

      //       plotX = centerX + Math.cos(plotXYRadians) * plotXYRadiusOffset;
      //       plotY = centerY + Math.sin(plotXYRadians) * plotXYRadiusOffset;

      //       failed = false;

      //       for (var c = 0; c < i; c++) {
      //         var circle = plottedCircleInfo[c];

      //         if (
      //           overlaps(
      //             circle.m_xPos,
      //             circle.m_yPos,
      //             circle.m_radius,
      //             plotX,
      //             plotY,
      //             radius + minGapBetweenCircle
      //           )
      //         ) {
      //           failed = true;
      //           break;
      //         }
      //       }

      //       plotXYRadians += 0.01;
      //       if (plotXYRadians > Math.PI * 2) {
      //         plotXYRadius += 1;
      //         plotXYRadians -= Math.PI * 2;
      //       }
      //     }

      //     plotXYRadians += Math.PI;

      //     bubbles[i].style.left = plotX - radius + "px";
      //     bubbles[i].style.top = plotY - radius + "px";
      //     bubbles[i].style.width = bubbles[i].offsetWidth + "px";
      //     bubbles[i].style.height = bubbles[i].offsetHeight + "px";

      //     plottedCircleInfo.push({
      //       m_xPos: plotX,
      //       m_yPos: plotY,
      //       m_radius: radius,
      //     });
      //   }
      // }

      function adjustLayout(containerID) {
        var container = document.getElementById(containerID);

        var bubbles = container.querySelectorAll(".bubble");

        var minGapBetweenCircle = 8;

        var plottedCircleInfo = [];

        var radius, plotX, plotY;

        var centerX = container.offsetWidth / 2;
        var centerY = container.offsetHeight / 2;

        var plotXYRadius = 1;
        var plotXYRadians = 0;

        var radius = 32;

        var containerWidth = container.offsetWidth;
        var lineY = centerY;

        for (var i = 0; i < bubbles.length; i++) {
          var failed = true;
          while (failed) {
            radius = bubbles[i].offsetWidth / 2;

            var plotXYRadiusOffset = plotXYRadius + Math.random() * 10;

            plotX = centerX + Math.cos(plotXYRadians) * plotXYRadiusOffset;
            plotY = lineY + Math.sin(plotXYRadians) * plotXYRadiusOffset;

            failed = false;

            for (var c = 0; c < i; c++) {
              var circle = plottedCircleInfo[c];

              if (
                overlaps(
                  circle.m_xPos,
                  circle.m_yPos,
                  circle.m_radius,
                  plotX,
                  plotY,
                  radius + minGapBetweenCircle
                )
              ) {
                failed = true;
                break;
              }
            }

            if (plotX + radius > containerWidth) {
              lineY += radius * 2 + minGapBetweenCircle;
              plotXYRadius = 1;
              plotXYRadians = 0;
              failed = true;
            }

            plotXYRadians += 0.01;
            if (plotXYRadians > Math.PI * 2) {
              plotXYRadius += 1;
              plotXYRadians -= Math.PI * 2;
            }
          }

          plotXYRadians += Math.PI;

          bubbles[i].style.left = plotX - radius + "px";
          bubbles[i].style.top = plotY - radius + "px";
          bubbles[i].style.width = bubbles[i].offsetWidth + "px";
          bubbles[i].style.height = bubbles[i].offsetHeight + "px";

          plottedCircleInfo.push({
            m_xPos: plotX,
            m_yPos: plotY,
            m_radius: radius,
          });
        }
      }

      adjustLayout("spreadCircles");
    },
  },
  mounted() {
    this.getDeals();
  },
};
</script>

<style lang="scss">
.catalog-page {
  .page-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .add-button {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
    box-shadow: (0px 25px 10px rgba(25, 25, 26, 0.01)),
      (0px 14px 8px rgba(25, 25, 26, 0.05)),
      (0px 6px 6px rgba(25, 25, 26, 0.09)), (0px 2px 3px rgba(25, 25, 26, 0.1)),
      (0px 0px 0px rgba(25, 25, 26, 0.1));
  }

  .index-100 {
    z-index: 100;
  }

  .van-overlay {
    z-index: 98 !important;
  }

  .van-popup {
    z-index: 99 !important;
  }

  .bubbles-holder {
    position: relative;
    height: 100vh;
    bottom: 120px;
    max-width: 375px;
    margin: auto;

    .bubble {
      position: absolute;
      margin: 4px;
    }
  }

  .card {
    margin-bottom: 8px;
  }

  .search-holder {
    margin-bottom: 16px;

    .input-search {
      margin-bottom: 8px;
    }
  }

  .selected-categories {
    display: flex;
    flex-wrap: wrap;

    .selected-category {
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}
</style>
