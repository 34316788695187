<template>
	<div class="input-search">
		<img src="@/assets/images/search-normal.svg" alt="..." />
		<van-field v-model="value" :placeholder="placeholder" :placeholder-style="{ color: 'red' }" @input="handleChange" />
	</div>
</template>

<script>
export default {
	props: {
		placeholder: {
			type: String,
			default: "Поиск"
		}
	},
	data() {
		return {
			value: ""
		};
	},
	computed: {
		themeVars() {
			return {
				vanSearchPadding: "20px",
				SearchBackground: "red"
			};
		}
	},
	methods: {
		handleChange(event) {
			this.$emit("input", event);
		}
	}
};
</script>

<style lang="scss" scoped>
.input-search {
	position: relative;
	display: flex;
	background: #e5e7e1;
	padding-left: 17px;
	border-radius: 100px;
	min-height: 40px;

	.van-field {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-left: 52px;
		font-weight: 500;
		background: transparent;

		::v-deep ::placeholder {
			color: #aea8a8;
		}

		::v-deep .van-cell__value {
			display: flex;
		}

		::v-deep .van-field__body {
			width: 100%;
		}
	}
}
</style>
