<template>
	<ul class="members">
		<li class="members-item" v-for="member in array" :key="member">
			<img :src="member" alt="..." />
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		array: {
			type: Array,
			default: () => []
		}
	}
};
</script>

<style lang="scss" scoped>
.members {
	display: flex;

	.members-item {
		width: 28px;
		height: 28px;

		&:not(:first-child) {
			margin-left: -13px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}
}
</style>
