<template>
	<div class="bubble" :style="style" @click="emitClick">
		<span class="bubble-emoji"> {{ item.emoji }}</span>
	</div>
</template>

<script>
export default {
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},

	computed: {
		style() {
			return { "--color": `#${this.item.color}`, "--size": `${this.getSize}px` };
		},
		getSize() {
			if (this.item.deals <= 0) {
				return 64;
			} else if (this.item.deals > 0 && this.item.deals < 5) {
				return 96;
			} else if (this.item.deals > 5) {
				return 120;
			}
		}
	},
	methods: {
		emitClick() {
			this.$emit("click");
		}
	}
};
</script>

<style lang="scss" scoped>
.bubble {
	position: relative;
	width: var(--size);
	height: var(--size);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		box-shadow: inset 0px 0px 0px 1px var(--color);
		border-color: var(--color);
	}

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--color);
		opacity: 0.32;
		border-radius: 50%;
	}

	.bubble-emoji {
		position: relative;
		z-index: 2;
	}
}
</style>
