var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",style:({
		backgroundColor: _vm.item.status === 0 ? `#${_vm.item.category.color}` : '#B3B3B3'
	})},[_c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('Members',{attrs:{"array":_vm.item.members}})],1),_c('div',{staticClass:"card-footer"},[(_vm.item.joined === 0)?_c('ul',{staticClass:"tags"},[(_vm.item.start_date)?_c('li',{staticClass:"tag"},[_vm._v("🧨 с "+_vm._s(_vm.item.start_date))]):_vm._e(),_c('li',{staticClass:"tag"},[_vm._v("⏳ "+_vm._s(_vm.frequency))])]):_vm._e(),_c('div',{staticClass:"card-date"},[_c('div',{class:[
					'date-day',
					{
						infinity: _vm.item.duration == 0 ? true : false
					}
				]},[_vm._v(" "+_vm._s(_vm.item.duration == 0 ? "8" : _vm.item.duration)+" ")]),_c('div',{staticClass:"date-name"},[_vm._v("дней")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }