import API from '@/api';
export default {
	getDeal: (params) => {
		return API.get(`/deals/${params.id}?today=${params.today}`);
	},
	getDeals: (params = {}) => {
		return API.get(`/deals`, {
			params
		});
	},
	searchDeals: (q) => {
		return API.get(`/deals?q=${q}`);
	},
	getDealsByCategory: (categories) => {
		return API.get(`/deals?categories[]=${categories}`)
	},
	getParticipantsDeal: (id) => {
		return API.get(`/deals/${id}/users?limit=20&offset=0`)
	},
	enjoy: (id) => {
		return API.post(`/deals/${id}/enjoy`)
	},
	finish: (id) => {
		return API.post(`/deals/${id}/finish`)
	},
	getReports: (id) => {
		return API.get(`/deals/${id}/reports`)
	},
	sendReports: (params = {}) => {
		return API.post(`/deals/${params.id}/reports`, params)
	},
	getProgress: (id) => {
		return API.get(`/deals/${id}/progress`)
	},
	createDeals: (params = {}) => {
		return API.post(`/deals`, params)
	},
	editDeals: (params = {}) => {
		let query = new URLSearchParams(params);

		return API.put(`/deals/${params.id}?${query}`)
	},
	deleteDeals: (params = {}) => {
		return API.delete(`/deals/${params.id}`)
	},
	complaintDeals: (params = {}) => {
		return API.delete(`/deals/${params.id}/complaint`)
	},
	setLike: (id) => {
		return API.post(`/reports/${id}/like`)
	},
	removeLike: (id) => {
		return API.delete(`/reports/${id}/like`)
	},
	sendComplaint: (id) => {
		return API.post(`/reports/${id}/complaint`)
	},
	deleteReport: (id) => {
		return API.delete(`/reports/${id}`)
	},
	editReports: (params = {}) => {
		let query = new URLSearchParams(params);
		return API.put(`/reports/${params.id}?${query}`)
	},
	setChannel: (id) => {
		return API.post(`/deals/${id}/channel`)
	},
	setChat: (id) => {
		return API.post(`/deals/${id}/chat`)
	},

};
